import React, { useEffect, useState, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import api from '../../services/Api';
import { UserContext } from '../../contexts/UserContext';


type FormFields = {
    email: string;
    password: string;
}

const Login: React.FC = () => {
    const [inputEmail, setInputEmail] = useState('contato@gustavoandrade.net');
    const [password, setPassword] = useState('112233');
    const { user, token, signIn } = useContext(UserContext);

    const signin = async () => {
        console.log("clickint");
        if (inputEmail.length == 0 || password.length == 0) {
            alert("Preencha todos os campos");
        }
        try {
            const response = await api.post('signin', {
                email: inputEmail, password
            });

            signIn(response.data);

        } catch (e) {
            console.log(e);
        }
    }

    return (
        <Container className='p-3' >
            <form >
                <div className="row">
                    <div className="col-sm-6 offset-sm-3">
                        <h4>Login no Sistema AMMonte</h4>
                        <Card className='p-3'>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Email"
                                    name="email"
                                    value={inputEmail}
                                    required
                                    onChange={(e) => { setInputEmail(e.target.value) }}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    value={password}
                                    name="password"
                                    placeholder="Password"
                                    onChange={(e) => { setPassword(e.target.value) }}
                                />
                            </div>
                            <button type='button' onClick={signin} className="btn btn-primary">Acessar</button>
                        </Card>
                    </div>
                </div>
            </form>
        </Container >
    )
}

export default Login;
