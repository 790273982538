import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import Container from 'react-bootstrap/esm/Container';
import HeaderNavbar from '../../components/HeaderNavbar';
import moment from 'moment';
import api from '../../services/Api';

interface ICategory {
  id: number;
  name: string;
}

interface IExpense {
  id: number;
  value: any;
  date: string;
  obs: string;
  category: ICategory;
}

const Expenses: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<Array<ICategory>>([]);
  const [category, setCategory] = useState<number>(0);
  const [date, setDate] = useState();
  const [value, setValue] = useState<any>();
  const [obs, setObs] = useState<string>('');
  const [expenses, setExpenses] = useState<IExpense[]>([]);
  const token = localStorage.getItem('@auth:token');
  const [startDate, setStateDate] = useState(moment().format('YYYY-MM-01'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

  const loadCategories = useCallback(async () => {
    try {
      const response = await api.get('categories', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      setCategories(response.data);
    } catch (e) {
      alert('Error ao carregar vendedores');
    }
  }, [token]);

  const loadExpenses = useCallback(async () => {
    try {
      const response = await api.get(
        `expenses?start_date=${startDate}&end_date=${endDate}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      console.log(response);
      setExpenses(response.data);
    } catch (e) {
      console.log(e.response);
    }
  }, [token, startDate, endDate]);

  useEffect(() => {
    loadCategories();
    loadExpenses();
  }, [loadCategories, loadExpenses]);

  useEffect(() => {
    loadExpenses();
  }, [startDate, endDate]);

  useEffect(() => {
    categories.map((s, index) => {
      if (index === 0) {
        setCategory(s.id);
      }
    });
  }, [categories]);

  function isValidData() {
    // Can't be all values equals to 0
    if (value == undefined || value <= 0) {
      return false;
    }

    return true;
  }

  async function insertExpense() {
    if (!isValidData()) {
      alert('Preencha os dados corretamente!');
      return false;
    }
    console.log('Adicionando despesa...');
    setLoading(true);
    try {
      const data = {
        category_id: category,
        date,
        value,
        obs,
      };
      console.log(data);
      const response = await api.post('expenses', data, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      alert('Despesa cadastrada com sucesso!');
      setValue(0);
      setObs('');
      setLoading(false);
      loadExpenses();
    } catch (e) {
      console.log(e);
      alert('Preencha todos os dados corretamente!');
      setLoading(false);
    }
  }

  return (
    <div>
      <HeaderNavbar />
      <Container className="mt-3">
        <h3>Cadastrar Despesa</h3>
        <div className="row">
          <div className="col-sm-4 mt-2">
            <label htmlFor="qtd">Categoria</label>
            <select
              defaultValue={category}
              className="form-control"
              onChange={(e) => {
                setCategory(parseInt(e.target.value));
              }}
            >
              {categories.map((currentCategory) => {
                return (
                  <option key={currentCategory.id} value={currentCategory.id}>
                    {currentCategory.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-sm-4 mt-3">
            <label htmlFor="qtd">Data</label>
            <input
              type="date"
              name="date"
              onChange={(e) => {
                setDate(e.target.value);
              }}
              required
              className="form-control mb-3"
              placeholder="Data"
            />
          </div>
          <div className="col-6">
            <label htmlFor="qtd">Valor</label>
            <input
              type="text"
              inputMode="decimal"
              name="value"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              required
              className="form-control mb-3"
              placeholder="0,00"
            />
          </div>
          <div className="col-sm-12">
            <label htmlFor="qtd">OBS</label>
            <input
              type="text"
              name="obs"
              value={obs}
              onChange={(e) => {
                setObs(e.target.value);
              }}
              required
              className="form-control mb-3"
              placeholder="OBS..."
            />
          </div>
          <div className="col-sm-4">
            {
              <button
                disabled={loading}
                className="btn btn-primary btn-block"
                onClick={insertExpense}
              >
                <b>+</b> Adicionar
              </button>
            }
          </div>
        </div>
        <div>
          <h3 className="mt-4">Últimas Despesas</h3>
          <div className="row">
            <div className="col-sm-6">
              <label htmlFor="qtd">Data Inicial</label>
              <input
                type="date"
                name="startDate"
                value={startDate}
                onChange={(e) => {
                  setStateDate(e.target.value);
                }}
                className="form-control mb-3"
                placeholder="0"
              />
            </div>
            <div className="col-sm-6">
              <label htmlFor="endDate">Data Final</label>
              <input
                type="date"
                name="endDate"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
                className="form-control mb-3"
                placeholder="0"
              />
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered table-dashed bg-white table-striped">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Categoria</th>
                  <th>Valor</th>
                  <th>OBS</th>
                </tr>
              </thead>
              <tbody>
                {expenses != undefined &&
                  expenses.map((expense) => {
                    return (
                      <tr key={expense.id}>
                        <td>{moment(expense.date).utc().format('DD/MM')}</td>
                        <td>{expense.category.name}</td>
                        <td>R$ {expense.value}</td>
                        <td>{expense.obs}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Expenses;
