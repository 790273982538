import React, { useEffect, useState, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import api from '../../services/Api';
import { UserContext } from '../../contexts/UserContext';


type FormFields = {
    email: string;
    password: string;
}

const Logout: React.FC = () => {


    const { signOut } = useContext(UserContext);

    useEffect(() => {
        signOut();
    })

    return (
        <Container className='p-3' >
            <h2>Você saiu do sistema, atualize a página.</h2>
        </Container >
    )
}

export default Logout;