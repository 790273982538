import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import Container from 'react-bootstrap/esm/Container';
import HeaderNavbar from '../../components/HeaderNavbar';
import moment from 'moment';
import api from '../../services/Api';

interface ITransaction {
  id: number;
  charge: number;
  sales: number;
  qtd: number;
  seller_id: number;
  seller_commission: number;
  user_commission: number;
}

interface IVale {
  vales: number;
  date: string;
  seller: string;
  seller_id: number;
}

interface IResult {
  deposits?: { deposits: number };
  transaction?: ITransaction;
  vale: IVale;
}

interface ISeller {
  id: number;
  name: string;
}

const Report: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [sellers, setSellers] = useState();
  const [selectedSeller, setSelectedSeller] = useState<number | null>(null);
  const [startDate, setStateDate] = useState(moment().format('YYYY-MM-01'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [resultDates, setResultDates] = useState<any[]>([]);
  const token = localStorage.getItem('@auth:token');

  useEffect(() => {
    loadResults();
  }, [startDate, endDate, selectedSeller]);

  function getSearchFields() {
    let searchString = `?start_date=${startDate}&end_date=${endDate}`;

    if (selectedSeller !== null) {
      searchString += `&seller_id=${selectedSeller}`;
    }
    return searchString;
  }

  const loadResults = useCallback(async () => {
    setLoading(true);
    try {
      console.log(getSearchFields());
      const response = await api.get('report' + getSearchFields(), {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      console.log('results', response.data);
      setResultDates(Object.keys(response.data.results));
      setResults(response.data.results);
      setSellers(response.data.sellers);
    } catch (e) {
      alert('Error ao carregar relatório');
    }
    setLoading(false);
  }, [token, selectedSeller, startDate, endDate]);

  function getDepositsValue(results: IResult) {
    return results.deposits !== undefined ? results.deposits.deposits : 0;
  }

  function getTransaction(results: IResult): ITransaction | null {
    return results.transaction !== undefined ? results.transaction : null;
  }

  function getVale(results: IResult): IVale | null {
    return results.vale !== undefined ? results.vale : null;
  }

  function getSellerComission(transaction: ITransaction): number {
    return transaction.charge * (transaction.seller_commission / 100);
  }

  function getManagerComission(transaction: ITransaction): number {
    return transaction.charge * (transaction.user_commission / 100);
  }

  function getSellerName(seller: any): any {
    try {
      return sellers[seller] || '';
    } catch (err) {
      return '';
    }
  }

  let totalCharge = 0;
  let totalVales = 0;
  let totalSellerCommission = 0;
  let totalManagerCommission = 0;

  return (
    <div>
      <HeaderNavbar />
      <Container className="mt-3">
        <h4>Relatório </h4>
        <div>
          <div className="row">
            <div className="col-sm-4">
              <label htmlFor="qtd">Vendedor</label>
              <select
                className="form-control"
                onChange={(e) => {
                  console.log('vendedor selecionado', e.target.value);
                  setSelectedSeller(parseInt(e.target.value));
                }}
              >
                <option>Selecione um Vendedor</option>
                {sellers !== undefined &&
                  Object.keys(sellers).map((sellerId) => {
                    return (
                      <option key={sellerId} value={sellerId}>
                        {getSellerName(sellerId)}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col-sm-4">
              <label htmlFor="qtd">Data Inicial</label>
              <input
                type="date"
                name="startDate"
                value={startDate}
                onChange={(e) => {
                  setStateDate(e.target.value);
                }}
                className="form-control mb-3"
                placeholder="0"
              />
            </div>
            <div className="col-sm-4">
              <label htmlFor="endDate">Data Final</label>
              <input
                type="date"
                name="endDate"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
                className="form-control mb-3"
                placeholder="0"
              />
            </div>
          </div>

          {loading && <h5>Carregando, aguarde...</h5>}
          <div className="table-responsive">
            <table className="table table-bordered table-dashed bg-white table-striped">
              <thead>
                <tr>
                  <th>Data</th>
                  <td>Vendedor</td>
                  <th>Cobrança</th>
                  <th>Comissão Vendedor</th>
                  {/* <th>Comissão Chefe Equipe</th> */}
                  <th>Vale</th>
                  <th>Saldo</th>
                </tr>
              </thead>
              <tbody>
                {results != undefined &&
                  resultDates.map((resultDate) => {
                    if (results[resultDate] === undefined) {
                      return;
                    }

                    const resultSellers = Object.keys(results[resultDate]);
                    return resultSellers.map((seller) => {
                      if (results[resultDate][seller] === undefined) {
                        return;
                      }

                      const resultDateContent = results[resultDate][seller];
                      const transaction = getTransaction(resultDateContent);
                      const vale = getVale(resultDateContent);

                      if (!(transaction !== null || vale !== null)) {
                        return;
                      }

                      if (transaction !== null) {
                        totalCharge += parseFloat(
                          transaction.charge.toString()
                        );
                        totalSellerCommission +=
                          getSellerComission(transaction);
                        totalManagerCommission +=
                          getManagerComission(transaction);
                      }

                      if (vale !== null) {
                        totalVales += parseFloat(vale.vales.toString());
                      }

                      return (
                        <tr
                          key={
                            transaction !== null
                              ? transaction.id
                              : vale !== null
                              ? vale.date
                              : ''
                          }
                        >
                          <td>{moment(resultDate).format('DD/MM/YYYY')}</td>
                          <td>{getSellerName(seller)}</td>
                          <td>
                            R${' '}
                            {transaction &&
                              transaction.charge.toString().replace('.', ',')}
                          </td>
                          <td>
                            R${' '}
                            {transaction &&
                              getSellerComission(transaction)
                                .toFixed(2)
                                .replace('.', ',')}
                          </td>
                          {/* <td>
                            R${' '}
                            {transaction &&
                              getManagerComission(transaction)
                                .toFixed(2)
                                .replace('.', ',')}
                          </td> */}
                          <td>
                            R${' '}
                            {(vale &&
                              vale.vales.toString().replace('.', ',')) ||
                              0}
                          </td>
                          <td></td>
                        </tr>
                      );
                    });
                  })}
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td>Total</td>
                  <td>R$ {totalCharge.toFixed(2).replace('.', ',')}</td>
                  <td>
                    R$ {totalSellerCommission.toFixed(2).replace('.', ',')}
                  </td>
                  {/* <td>
                    R$ {totalManagerCommission.toFixed(2).replace('.', ',')}
                  </td> */}
                  <td>
                    R$ {totalVales.toFixed(2).toString().replace('.', ',')}
                  </td>
                  <td style={{ fontWeight: 'bold' }}>
                    R${' '}
                    {(totalSellerCommission - totalVales)
                      .toFixed(2)
                      .toString()
                      .replace('.', ',') || 0}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Report;
