import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import Container from 'react-bootstrap/esm/Container';
import HeaderNavbar from '../../components/HeaderNavbar';
import moment from 'moment';
import api from '../../services/Api';

interface ISeller {
  id: number;
  name: string;
}

interface ITransaction {
  id: number;
  seller: ISeller;
  value_charge: any;
  value_devolution: any;
  value_sale: any;
  qtd: any;
  date: string;
  obs: string;
}

type TotalsProps = {
  saldo: string;
  expenses: string;
  charge: string;
  deposits: string;
  vales: string;
  purchase: string;
  saldo_pagar: string;
};

const Transactions: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [sellers, setSellers] = useState<Array<ISeller>>([]);
  const [seller, setSeller] = useState<number>(0);
  const [date, setDate] = useState();
  const [valueCharge, setValueCharge] = useState<any>();
  const [valueDevolution, setValueDevolution] = useState<any>();
  const [valueSale, setValueSale] = useState<any>();
  const [qtd, setQtd] = useState<any>();
  const [obs, setObs] = useState<string>('');
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [startDate, setStateDate] = useState(moment().format('YYYY-MM-01'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [totals, setTotals] = useState({} as TotalsProps);
  const token = localStorage.getItem('@auth:token');

  const loadSaldo = useCallback(async () => {
    try {
      const response = await api.get(`report/saldo?start_date=${startDate}&end_date=${endDate}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      console.log("######## Totals");
      console.log(response);
      setTotals(response.data);
    } catch (e) {
      alert('Error ao carregar saldo');
    }
  }, [endDate, startDate, token]);

  const loadSellers = useCallback(async () => {
    try {
      const response = await api.get('sellers', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      setSellers(response.data);
    } catch (e) {
      alert('Error ao carregar vendedores');
    }
  }, [token]);

  const loadTransactions = useCallback(async () => {
    try {
      const response = await api.get(
        `transactions?start_date=${startDate}&end_date=${endDate}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      console.log(response);
      setTransactions(response.data);
    } catch (e) {
      console.log(e);
    }
  }, [startDate, endDate, token]);

  useEffect(() => {
    loadSellers();
    loadTransactions();
    loadSaldo();
  }, [loadSellers, loadTransactions, loadSaldo]);

  useEffect(() => {
    loadTransactions();
  }, [startDate, endDate, loadTransactions]);

  useEffect(() => {
    sellers.map((s, index) => {
      if (index === 0) {
        setSeller(s.id);
      }
    });
  }, [sellers]);

  function isValidData() {
    // Can't be all values equals to 0
    if (valueCharge == 0 && valueDevolution == 0 && valueSale == 0) {
      return false;
    }

    return true;
  }

  async function insertTransaction() {
    if (!isValidData()) {
      alert('Preencha os dados corretamente!');
      return false;
    }
    console.log('Adicionando transação...');
    setLoading(true);
    try {
      const data = {
        date,
        value_charge: valueCharge,
        value_devolution: valueDevolution,
        value_sale: valueSale,
        qtd,
        obs,
        seller_id: seller,
      };
      console.log(data);
      const response = await api.post('transactions', data);
      alert('Entrada cadastrada com sucesso!');
      setValueCharge('');
      setValueDevolution('');
      setValueSale('');
      setQtd('');
      setObs('');
      setLoading(false);
      loadTransactions();
      loadSaldo();
    } catch (e) {
      console.log(e);
      alert('Preencha todos os dados corretamente!');
      setLoading(false);
    }
  }

  return (
    <div>
      <HeaderNavbar />
      <Container className="mt-3">
        <h3>Cadastrar Entrada</h3>
        <div className="row">
          <div className="col-sm-4">
            <label htmlFor="qtd">Vendedor</label>
            <select
              defaultValue={seller}
              className="form-control"
              onChange={(e) => {
                console.log(e.target.value);
                setSeller(parseInt(e.target.value));
              }}
            >
              {sellers.map((currentSeller) => {
                return (
                  <option key={currentSeller.id} value={currentSeller.id}>
                    {currentSeller.name}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="col-sm-4 mt-3">
            <label htmlFor="qtd">Data</label>
            <input
              type="date"
              name="date"
              onChange={(e) => {
                setDate(e.target.value);
              }}
              required
              className="form-control mb-3"
              placeholder="Quantidade de Adultos"
            />
          </div>
          <div className="col-6">
            <label htmlFor="qtd">Valor de Cobrança</label>
            <input
              type="text"
              inputMode="decimal"
              name="valueCharge"
              value={valueCharge}
              onChange={(e) => {
                setValueCharge(e.target.value);
              }}
              required
              className="form-control mb-3"
              placeholder="0.00"
            />
          </div>
          <div className="col-6">
            <label htmlFor="qtd">Valor Devolução</label>
            <input
              type="text"
              inputMode="decimal"
              name="valueDevolution"
              value={valueDevolution}
              onChange={(e) => {
                setValueDevolution(e.target.value);
              }}
              required
              className="form-control mb-3"
              placeholder="0.00"
            />
          </div>

          <div className="col-6">
            <label htmlFor="qtd">Quantidade de Fichas</label>
            <input
              type="number"
              name="qtd"
              value={qtd}
              onChange={(e) => {
                setQtd(parseInt(e.target.value));
              }}
              required
              className="form-control mb-3"
              placeholder="0"
            />
          </div>
          <div className="col-6">
            <label htmlFor="qtd">Valor de Vendas</label>
            <input
              type="text"
              inputMode="decimal"
              name="valueSale"
              value={valueSale}
              onChange={(e) => {
                setValueSale(e.target.value);
              }}
              required
              className="form-control mb-3"
              placeholder="0.00"
            />
          </div>
          <div className="col-sm-12">
            <label htmlFor="qtd">OBS</label>
            <input
              type="text"
              name="obs"
              value={obs}
              onChange={(e) => {
                setObs(e.target.value);
              }}
              required
              className="form-control mb-3"
              placeholder="OBS..."
            />
          </div>
          <div className="col-sm-4">
            {
              <button
                disabled={loading}
                className="btn btn-primary btn-block"
                onClick={insertTransaction}
              >
                <b>+</b> Adicionar
              </button>
            }
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-sm-12 mt-4">
              <h5>Filtros </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <label htmlFor="qtd">Data Inicial</label>
              <input
                type="date"
                name="startDate"
                value={startDate}
                onChange={(e) => {
                  setStateDate(e.target.value);
                }}
                className="form-control mb-3"
                placeholder="0"
              />
            </div>
            <div className="col-sm-6">
              <label htmlFor="endDate">Data Final</label>
              <input
                type="date"
                name="endDate"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
                className="form-control mb-3"
                placeholder="0"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h3 className="mt-4">Cobranças</h3>
              <h5 style={{ fontWeight: 400 }}>R$ {totals.charge}</h5>
            </div>
            <div className="col-6">
              <h3 className="mt-4">Compras a pagar</h3>
              <h5 style={{ fontWeight: 400 }}>R$ {totals.purchase}</h5>
            </div>
            <div className="col-6">
              <h3 className="mt-4">Despesas</h3>
              <h5 style={{ fontWeight: 400 }}>R$ {totals.expenses}</h5>
            </div>
            <div className="col-6">
              <h3 className="mt-4">Depositos</h3>
              <h5 style={{ fontWeight: 400 }}>R$ {totals.deposits}</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h3 className="mt-4">Vales</h3>
              <h5 style={{ fontWeight: 400 }}>R$ {totals.vales}</h5>
            </div>
            <div className="col-6">
              <h3 className="mt-4">Saldo a Pagar</h3>
              <h5 style={{ fontWeight: 400 }}>R$ {totals.saldo_pagar}</h5>
            </div>
          </div>

          <h3 className="mt-4">Saldo em Caixa</h3>
          <h5 style={{ fontWeight: 400 }}>R$ {totals.saldo}</h5>
          <h3 className="mt-4">Últimas Entradas</h3>
          <div className="table-responsive">

            <table className="table table-bordered table-dashed  bg-white table-striped">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Vendedor</th>
                  <th>Devolução</th>
                  <th>Cobrança</th>
                  <th>Fichas</th>
                  <th>Vendas</th>
                  <th>OBS</th>
                </tr>
              </thead>
              <tbody>
                {transactions != undefined &&
                  transactions.map((transaction) => {
                    return (
                      <tr key={transaction.id}>
                        <td>
                          {moment(transaction.date).utc().format('DD/MM')}
                        </td>
                        <td>{transaction.seller.name}</td>
                        <td>R$ {transaction.value_devolution}</td>
                        <td>R$ {transaction.value_charge}</td>
                        <td>{transaction.qtd}</td>
                        <td>R$ {transaction.value_sale}</td>
                        <td>{transaction.obs}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Transactions;
