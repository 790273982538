import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';
import Login from '../pages/Login';
import Transactions from '../pages/Transactions';
import Expenses from '../pages/Expenses';
import Logout from '../pages/Logout';
import Bills from '../pages/Deposits';
import Vales from '../pages/Vales';
import Inventory from '../pages/Inventory';
import Report from '../pages/Report';
import Purchases from '../pages/Purchases';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={Login} />

      <Route path="/dashboard" component={Transactions} isPrivate />
      <Route path="/transactions" component={Transactions} isPrivate />
      <Route path="/vales" component={Vales} isPrivate />
      <Route path="/reports" component={Report} isPrivate />
      <Route path="/purchases" component={Purchases} isPrivate />
      <Route path="/inventory" component={Inventory} isPrivate />
      <Route path="/expenses" component={Expenses} isPrivate />
      <Route path="/bills" component={Bills} isPrivate />
      <Route path="/logout" component={Logout} isPrivate />
    </Switch>
  );
};

export default Routes;
