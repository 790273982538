import React, { useContext } from 'react';
import {
    Route as ReactDOMRoute,
    RouteProps as ReactDOMRouteProps,
    Redirect
} from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

interface ReactProps extends ReactDOMRouteProps {
    isPrivate?: boolean,
    component: React.ComponentType
}

const Route: React.FC<ReactProps> = ({ isPrivate = false, component: Component, ...rest }) => {

    const { user } = useContext(UserContext);

    return (
        <ReactDOMRoute
            {...rest}
            render={() => {
                return isPrivate == !!user ? (
                    <Component />
                ) : (
                        <Redirect to={{ pathname: isPrivate ? '/' : '/dashboard' }} />
                    )
            }}
        />
    )
}

export default Route;