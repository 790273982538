import axios from 'axios';
import React, {useEffect, useState, useCallback} from 'react';
import Container from 'react-bootstrap/esm/Container';
import HeaderNavbar from '../../components/HeaderNavbar';
import moment from 'moment';
import api from '../../services/Api';

interface ISeller {
  id: number;
  name: string;
}

interface IVale {
  id: number;
  seller_id: number;
  seller: ISeller;
  value: any;
  date: string;
  obs: string;
}

const Vales: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState();
  const [value, setValue] = useState<any>();
  const [obs, setObs] = useState<string>('');
  const [seller, setSeller] = useState<number>(0);
  const [vales, setVales] = useState<IVale[]>([]);
  const token = localStorage.getItem('@auth:token');
  const [startDate, setStateDate] = useState(moment().format('YYYY-MM-01'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [sellers, setSellers] = useState<Array<ISeller>>([]);

  const loadSellers = useCallback(async () => {
    const token = localStorage.getItem('@auth:token');
    try {
      const response = await api.get('sellers', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      setSellers(response.data);
    } catch (e) {
      alert('Error ao carregar vendedores');
    }
  }, []);


  const loadVales = useCallback(async () => {
    try {
      const response = await api.get(
        `vales?start_date=${startDate}&end_date=${endDate}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
      console.log(response);
      setVales(response.data);
    } catch (e) {
      console.log(e.response);
    }
  }, [token, startDate, endDate]);

  useEffect(() => {
    loadVales();
    loadSellers();
  }, [loadVales]);

  function isValidData() {
    // Can't be all values equals to 0
    if (value === undefined || value <= 0) {
      return false;
    }

    return true;
  }

  async function insertVale() {
    if (!isValidData()) {
      alert('Preencha os dados corretamente!');
      return false;
    }
    console.log('Adicionando vale...');
    setLoading(true);
    try {
      const data = {
        seller_id: seller,
        date,
        value,
        obs,
      };
      console.log(data);
      const response = await api.post('vales', data, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      alert('Vale cadastrado com sucesso!');
      setValue(0);
      setObs('');
      setLoading(false);
      loadVales();
    } catch (e) {
      console.log(e);
      alert('Preencha todos os dados corretamente!');
      setLoading(false);
    }
  }

  return (
    <div>
      <HeaderNavbar/>
      <Container className="mt-3">
        <h3>Cadastrar Vale</h3>
        <div className="row">
          <div className="col-sm-4 mt-3">
            <label htmlFor="qtd">Vendedor</label>
            <select
              defaultValue={seller}
              className="form-control"
              onChange={(e) => {
                console.log(e.target.value);
                setSeller(parseInt(e.target.value));
              }}
            >
              {sellers.map((currentSeller) => {
                return (
                  <option key={currentSeller.id} value={currentSeller.id}>
                    {currentSeller.name}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="col-sm-4 mt-3">
            <label htmlFor="qtd">Data</label>
            <input
              type="date"
              name="date"
              onChange={(e) => {
                setDate(e.target.value);
              }}
              required
              className="form-control mb-3"
              placeholder="Data"
            />
          </div>
          <div className="col-6">
            <label htmlFor="qtd">Valor</label>
            <input
              type="text"
              inputMode="decimal"
              name="value"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              required
              className="form-control mb-3"
              placeholder="0,00"
            />
          </div>
          <div className="col-sm-12">
            <label htmlFor="qtd">OBS</label>
            <input
              type="text"
              name="obs"
              value={obs}
              onChange={(e) => {
                setObs(e.target.value);
              }}
              required
              className="form-control mb-3"
              placeholder="OBS..."
            />
          </div>
          <div className="col-sm-4">
            {
              <button
                disabled={loading}
                className="btn btn-primary btn-block"
                onClick={insertVale}
              >
                <b>+</b> Adicionar
              </button>
            }
          </div>
        </div>
        <div>
          <h3 className="mt-4">Últimos Registros</h3>
          <div className="table-responsive">
            <table className="table table-bordered table-dashed bg-white table-striped">
              <thead>
              <tr>
                <th>Data</th>
                <th>Vendedor</th>
                <th>Valor</th>
                <th>OBS</th>
              </tr>
              </thead>
              <tbody>
              {vales != undefined &&
                vales.map((vale) => {
                  return (
                    <tr key={vale.id}>
                      <td>{moment(vale.date).utc().format('DD/MM')}</td>
                      <td>R$ {vale.seller.name}</td>
                      <td>R$ {vale.value}</td>
                      <td>{vale.obs}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Vales;
