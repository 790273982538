import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

export default function HeaderNavbar() {
  return (
    <Navbar expand="lg" variant="dark" bg="dark">
      <Navbar.Brand href="#">AMMonte</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/transactions">Entradas</Nav.Link>
          <Nav.Link href="/expenses">Despesas</Nav.Link>
          <Nav.Link href="/bills">Deposito</Nav.Link>
          <Nav.Link href="/vales">Vales</Nav.Link>
          <Nav.Link href="/inventory">Estoque</Nav.Link>
          <Nav.Link href="/reports">Relatório</Nav.Link>
          <Nav.Link href="/purchases">Compras a Pagar</Nav.Link>
          <Nav.Link href="/logout">Sair</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
