import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes'
import { UserContextProvider } from './contexts/UserContext'

function App() {
    return (
        <BrowserRouter>
            <UserContextProvider>
                <Routes />
            </UserContextProvider>
        </BrowserRouter>
    )
}

export default App;
