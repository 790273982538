import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import Container from 'react-bootstrap/esm/Container';
import HeaderNavbar from '../../components/HeaderNavbar';
import moment from 'moment';
import api from '../../services/Api';

interface IPurchase {
  id: number;
  value: any;
  date: string;
  obs: string;
}

const Purchases: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState(moment().format('YYYY'));
  const token = localStorage.getItem('@auth:token');
  const [purchases, setPurshases] = useState<IPurchase[]>([]);

  const loadPurchases = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get(`purchases?year=${year}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      console.log(response);
      setPurshases(response.data);
    } catch (e) {
      console.log(e.response);
    }
    setLoading(false);
  }, [year, token]);

  useEffect(() => {
    loadPurchases();
  }, [loadPurchases]);

  return (
    <div>
      <HeaderNavbar />
      <Container className="mt-3">
        <h3>Compras a Pagar</h3>
        {loading && <div>Carregando, aguarde...</div>}
        <div className="row">
          <div className="col-sm-4 mt-3">
            <label htmlFor="qtd">Ano</label>
            <input
              type="year"
              name="year"
              onChange={(e) => {
                setYear(e.target.value);
              }}
              required
              className="form-control mb-3"
              placeholder="Ano"
            />
          </div>
        </div>
        <div>
          <h3 className="mt-4">Últimos Registros</h3>
          <div className="table-responsive">
            <table className="table table-bordered table-dashed bg-white table-striped">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Valor</th>
                  <th>OBS</th>
                </tr>
              </thead>
              <tbody>
                {purchases != undefined &&
                  purchases.map((purchase) => {
                    return (
                      <tr key={purchase.id}>
                        <td>{moment(purchase.date).utc().format('DD/MM')}</td>
                        <td>
                          {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(purchase.value)}
                        </td>
                        <td>{purchase.obs}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Purchases;
