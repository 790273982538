import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import Container from 'react-bootstrap/esm/Container';
import HeaderNavbar from '../../components/HeaderNavbar';
import moment from 'moment';
import api from '../../services/Api';

interface IInventory {
  id: number;
  value: any;
  date: string;
  obs: string;
  type: string;
}

const Inventory: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [currentInventory, setCurrentInventory] = useState(0);
  const [inventories, setInventories] = useState<IInventory[]>([]);
  const token = localStorage.getItem('@auth:token');

  useEffect(() => {
    loadInventory();
  }, []);

  const loadInventory = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get('inventory', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      setCurrentInventory(parseFloat(response.data.inventory_value));
      setInventories(response.data.inventory_history);
    } catch (e) {
      alert('Error ao carregar vendedores');
    }
    setLoading(false);
  }, [token]);

  return (
    <div>
      <HeaderNavbar />
      <Container className="mt-3">
        <h4>Estoque Atual </h4>
        <h2 style={{ color: 'green' }}>R$ {currentInventory.toFixed(2)}</h2>
        <div>
          <h3 className="mt-4">Últimos Registros</h3>
          <div className="table-responsive">
            <table className="table table-bordered table-dashed bg-white table-striped">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Valor</th>
                  <th>Categoria</th>
                  <th>OBS</th>
                </tr>
              </thead>
              <tbody>
                {inventories != undefined &&
                  inventories.map((inventory) => {
                    return (
                      <tr key={inventory.id}>
                        <td>{moment(inventory.date).utc().format('DD/MM')}</td>
                        <td>R$ {inventory.value}</td>
                        <td>{inventory.type}</td>
                        <td>{inventory.obs}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Inventory;
