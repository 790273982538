import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    * {
        margin: 0px;
        padding: 0px;
    }

    body {
        margin: 0px;
        padding: 0px;
        background: #F5F5F5;
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 500;
    }

		h1,h2,h3,h4,h5 {
        font-weight: bold;
    }

    .bg-white {
        background: white
    }

    .text-white {
        color: white !important;
    }

    .min-text {
        font-size: 12px
    }

    .text-budget {
        font-size: 12px;
        height: 300px !important;
    }

    table {
        width: 100%;
    }

    tr {
        padding: 2px;
        margin: 2px;
    }
    td {
        padding: 5px !important;
        margin: 2px;
        font-size: 11px;
    }

      th {
        padding: 5px !important;
        margin: 2px;
        font-size: 12px;
    }
`
